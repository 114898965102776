import React, {useState} from 'react';
import './PaymentForm.css';
import axios from "axios";
import alertify from "alertifyjs";
const PaymentForm = () => {
    const [formData, setFormData] = useState({});

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleInformationChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData, information: {
                ...formData.information, [name]: value
            }
        })
    };

    const handleIdentifiersChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData, identifiers: {
                ...formData.identifiers, [name]: value
            }
        })
    };
    const handlePayeeChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData, payee: {
                ...formData.payee, [name]: value
            }
        })
    };
    const handlePayeeInsChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData, payee: {
                ...formData.payee, institution_information: {
                    ...formData.payee.institution_information,
                    [name]: value
                }
            }
        })
    };
    const handlePayerChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData, payer: {
                ...formData.payer, [name]: value
            }
        })
    };
    const handleIntermediary_bankChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData, intermediary_bank: {
                ...formData.intermediary_bank, [name]: value
            }
        })
    };
    const handlePayerInsChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData, payer: {
                ...formData.payer, institution_information: {
                    ...formData.payer.institution_information,
                    [name]: value
                }
            }
        })
    };
    console.log("tes", formData)
    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(
            "https://api.nextplaytec.com/request",
            // "http://localhost:3030/request",
            {data: formData},
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        ).then(res => {
            console.log(res);
            alertify.success("Payment with Payment id ".concat(res.data?.payment_id) + " successfully ".concat(res.data?.status));
            }


        ).catch(err => {
            console.log(err?.response?.data);
            alertify.error(err?.response?.data?.errors[0]?.description || "Sorry,Something went wrong!")
        })
    };

    return (
        <>


            <h1>Wells fargo Payment </h1>
            <div className="payment-form-container">
                <form onSubmit={handleSubmit} className="payment-form">
                    <div className="payment-form-row">
                        <label>
                            Payment Date:
                            <input
                                type="date"
                                name="value_date"
                                placeholder="dd-mm-yyyy"
                                min={new Date().toISOString().split('T')[0]}
                                value={formData?.value_date}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Payment Amount:
                            <input
                                type="number"
                                name="payment_amount"
                                value={formData?.payment_amount}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Payment Currency:
                            <input
                                type="text"
                                name="payment_currency"
                                value={formData?.payment_currency}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Third party reference number:
                            <input
                                type="text"
                                name="third_party_reference_number"
                                value={formData?.identifiers?.third_party_reference_number}
                                onChange={handleIdentifiersChange}
                            />
                        </label>
                    </div>
                    <div className="payment-form-row">
                        <label>
                            Customer reference number:
                            <input
                                type="text"
                                name="customer_reference_number"
                                value={formData?.identifiers?.customer_reference_number}
                                onChange={handleIdentifiersChange}
                            />
                        </label>
                        <label>
                            Payment purpose description:
                            <input
                                type="text"
                                name="payment_purpose_description"
                                value={formData?.payment_purpose_description}
                                onChange={handleInformationChange}
                            />
                        </label>
                    </div>
                    <div style={{
                        display: "flex",
                        margin: "1.8rem",
                        fontSize: "20px",
                        fontWeight: "bold"
                    }}>
                        <label>
                            Payee Information
                        </label>
                    </div>
                    <div className="payment-form-row">
                        <label>
                            Name:
                            <input
                                type="text"
                                name="name"
                                value={formData?.payee?.name}
                                onChange={handlePayeeChange}
                            />
                        </label>
                        <label>
                            Address:
                            <input
                                type="text"
                                name="address"
                                value={formData?.payee?.address}
                                onChange={handlePayeeChange}
                            />
                        </label>
                        <label>
                            Identification:
                            <input
                                type="text"
                                name="identification"
                                value={formData?.payee?.identification}
                                onChange={handlePayeeChange}
                            />
                        </label>
                        <label>
                            Phone Number:
                            <input
                                type="text"
                                name="phone"
                                value={formData?.payee?.phone}
                                onChange={handlePayeeChange}
                            />
                        </label>
                        <label>
                            Emails:
                            <input
                                type="text"
                                name="emails"
                                value={formData?.payee?.emails}
                                onChange={handlePayeeChange}
                            />
                        </label>
                        <label>
                            Bank account number:
                            <input
                                type="text"
                                name="bank_account_number"
                                value={formData?.payee?.bank_account_number}
                                onChange={handlePayeeChange}
                            />
                        </label>
                        <label>
                            Account type code:
                            <input
                                type="text"
                                name="account_type_code"
                                value={formData?.payee?.account_type_code}
                                onChange={handlePayeeChange}
                            />
                        </label>
                        <label>
                            Bank Name:
                            <input
                                type="text"
                                name="name"
                                value={formData?.payee?.institution_information?.name}
                                onChange={handlePayeeInsChange}
                            />
                        </label>
                        <label>
                            Bank routing transit number:
                            <input
                                type="text"
                                name="routing_transit_number"
                                value={formData?.payee?.institution_information?.routing_transit_number}
                                onChange={handlePayeeInsChange}
                            />
                        </label>
                    </div>
                    <div style={{
                        display: "flex",
                        margin: "1.8rem",
                        fontSize: "20px",
                        fontWeight: "bold"
                    }}>
                        <label>
                            Payer Information
                        </label>
                    </div>
                    <div className="payment-form-row">
                        <label>
                            Name:
                            <input
                                type="text"
                                name="name"
                                value={formData?.payer?.name}
                                onChange={handlePayerChange}
                            />
                        </label>
                        <label>
                            Address:
                            <input
                                type="text"
                                name="address"
                                value={formData?.payer?.address}
                                onChange={handlePayerChange}
                            />
                        </label>
                        <label>
                            Bank account number:
                            <input
                                type="text"
                                name="bank_account_number"
                                value={formData?.payer?.bank_account_number}
                                onChange={handlePayerChange}
                            />
                        </label>
                        <label>
                            Account type code:
                            <input
                                type="text"
                                name="account_type_code"
                                value={formData?.payer?.account_type_code}
                                onChange={handlePayerChange}
                            />
                        </label>
                        <label>
                            Bank Name:
                            <input
                                type="text"
                                name="name"
                                value={formData?.payer?.institution_information?.name}
                                onChange={handlePayerInsChange}
                            />
                        </label>
                        <label>
                            Bank identified code:
                            <input
                                type="text"
                                name="bank_identified_code"
                                value={formData?.payer?.institution_information?.bank_identified_code}
                                onChange={handlePayerInsChange}
                            />
                        </label>
                        <label>
                            Bank routing transit number:
                            <input
                                type="text"
                                name="routing_transit_number"
                                value={formData?.payer?.institution_information?.routing_transit_number}
                                onChange={handlePayerInsChange}
                            />
                        </label>
                    </div>
                    <div style={{
                        display: "flex",
                        margin: "1.8rem",
                        fontSize: "20px",
                        fontWeight: "bold"
                    }}>
                        <label>
                            Intermediary bank Information
                        </label>
                    </div>
                    <div className="payment-form-row">
                        <label>
                            Name:
                            <input
                                type="text"
                                name="name"
                                value={formData?.intermediary_bank?.name}
                                onChange={handleIntermediary_bankChange}
                            />
                        </label>
                        <label>
                            Address:
                            <input
                                type="text"
                                name="address"
                                value={formData?.intermediary_bank?.address}
                                onChange={handleIntermediary_bankChange}
                            />
                        </label>
                        <label>
                            Bank routing transit number:
                            <input
                                type="text"
                                name="routing_transit_number"
                                value={formData?.intermediary_bank?.routing_transit_number}
                                onChange={handleIntermediary_bankChange}
                            />
                        </label>
                    </div>
                    {/* Add other fields here similarly */}
                    <button type="submit">Submit</button>
                </form>
            </div>
        </>

    );
};

export default PaymentForm;
