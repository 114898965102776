import './App.css';
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/semantic.css";
import 'bootstrap/dist/css/bootstrap.css'
import PaymentForm from "./basicForm";
import { BrowserRouter as Router} from "react-router-dom";
import  {Nav, Navbar,Container} from "react-bootstrap";
import {Link,Route,Routes} from "react-router-dom";
import React from "react";
import ShowData from "./FetchData";
import NavBar from "./navBar";



function App() {
  return (
    <div className="App">
        <Router >
            <NavBar />
            <Routes>
                <Route exact path="/" element={<PaymentForm />} />
                <Route path="/data" element={<ShowData/>} />

            </Routes>
            {/*<Navbar bg="light" expand="lg">*/}
            {/*    <Navbar.Brand href="/">React Navbar</Navbar.Brand>*/}
            {/*    <Navbar.Toggle aria-controls="basic-navbar-nav" />*/}
            {/*    <Navbar.Collapse id="basic-navbar-nav">*/}
            {/*        <Nav className="mr-auto">*/}
            {/*            <Nav.Link as={Link} to="/">Home</Nav.Link>*/}
            {/*            <Nav.Link as={Link} to="/about">About</Nav.Link>*/}
            {/*        </Nav>*/}
            {/*    </Navbar.Collapse>*/}
            {/*</Navbar>*/}
        </Router>

    </div>
  );
}

export default App;
