import React, {useEffect, useState} from 'react';
import './PaymentForm.css';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";
import alertify from "alertifyjs";
const ShowData = () => {
    const [fetchData, setFetchData] = useState({});

    console.log("fetc",fetchData)
useEffect(  ()=>{
  try{
      const data =   async () => {
          const responseData =  await axios.get("https://api.nextplaytec.com/payment-status",
              {
                  headers: {
                      "Content-Type": "application/json"
                  }
              }
          )
          setFetchData(responseData.data.data)
      };
data()
  }catch (error){
      console.log(error)
      alertify.error(error?.response?.data?.errors[0]?.description || "Sorry,Something went wrong!")
  }
},[])
    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col">
                    <h2>Payment Details</h2>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Payment Information</h5>
                            <p><strong>Type:</strong> {fetchData?.payment?.payment_type}</p>
                            <p><strong>Value Date:</strong> {fetchData?.payment?.value_date}</p>
                            <p><strong>Amount:</strong> {fetchData?.payment?.payment_amount} {fetchData?.payment?.payment_currency}</p>
                            <p><strong>Payment Indicator:</strong> {fetchData?.payment?.payment_indicator}</p>
                            <p><strong>Credit Amount:</strong> {fetchData?.payment?.credit_amount} {fetchData?.payment?.credit_currency}</p>
                            <p><strong>Debit Amount:</strong> {fetchData?.payment?.debit_amount} {fetchData?.payment?.debit_currency}</p>
                            <p><strong>Duplicate Check Flag:</strong> {fetchData?.payment?.duplicate_check_flag.toString()}</p>
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-body">
                            <h5 className="card-title">Payee Information</h5>
                            <p><strong>Name:</strong> {fetchData?.payee?.name}</p>
                            <p><strong>Identification:</strong> {fetchData?.payee?.identification}</p>
                            <p><strong>Bank Account Number:</strong> {fetchData?.payee?.bank_information.bank_account_number}</p>
                            <p><strong>Account Name:</strong> {fetchData?.payee?.bank_information.account_name}</p>
                            <p><strong>Account Currency:</strong> {fetchData?.payee?.bank_information.account_currency}</p>
                            <p><strong>Account Type Code:</strong> {fetchData?.payee?.bank_information.account_type_code}</p>
                            <p><strong>Routing Transit Number:</strong> {fetchData?.payee?.bank_information.institution_information.routing_transit_number}</p>
                            <p><strong>Country Code:</strong> {fetchData?.payee?.bank_information.institution_information.address.country_code}</p>
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-body">
                            <h5 className="card-title">Payer Information</h5>
                            <p><strong>Name:</strong> {fetchData?.payer?.name}</p>
                            <p><strong>Bank Account Number:</strong> {fetchData?.payer?.bank_information.bank_account_number}</p>
                            <p><strong> Account Currency:</strong> {fetchData?.payer?.bank_information.account_currency}</p>
                            <p><strong> Account type code:</strong> {fetchData?.payer?.bank_information.account_type_code}</p>
                            <p><strong> Institution information name:</strong> {fetchData?.payer?.bank_information.institution_information.name}</p>
                            <p><strong> Institution information name:</strong> {fetchData?.payer?.bank_information.institution_information.routing_transit_number}</p>
                            <p><strong> Institution information name:</strong> {JSON.stringify(fetchData?.payer?.bank_information.institution_information.address)}</p>


                            }
                        </div>
                    </div>
                    {/* Include other sections of payment details */}
                </div>
            </div>
        </div>
    );
}


export default ShowData;
